import React from 'react'
/* eslint-disable import/named */
import {
  LoginPage,
  ReportPage,
  ReportDemoPage,
  SelectAccountPage,
  GeneratingPage,
  TermsPage,
  PrivacyPage,
  // LandingPage,
  HomePage,
  UnauthenticatedDiscoverAds,
} from '@pages'
import KnowledgeAndGuides from '@pages/learning-center/knowledge-and-guides'
import ForgotPassword from '@pages/forgot-password'
import PasswordReset from '@pages/password-reset'
import Dashboard from '@pages/dashboard'
import Settings from '@pages/settings'
import LoginRoute from '@pages/login/login-route'
import CodePage from '@pages/code'
import Register from '@pages/Register'
import GeneratePassword from '@pages/generate-password'
import AcceptInvite from '@pages/accept-invite'
import Calculator from '@pages/calculator'
import Benchmark from '@pages/benchmark'
import ReportDashboard from '@pages/report-dashboard'
import LeadFormPage from '@pages/lead-form'
import SelectSampleAccountPage from '@pages/select-sample-account'
import LearningCenter from '@pages/learning-center'
import SearchFeature from '@pages/learning-center/search-feature'
import BoardsDetail from '@pages/boards/boards-feature/BoardsDetail'
import NotFound from '@pages/404NotFound'
import SharedBoards from '@components/Boards/SharedBoards'
import BrandsDetail from '@components/DiscoverAds/BrandsDetail'
import ResetPassword from '@pages/ResetPassword'
import AcceptLoginInvite from '@pages/accept-login-invite'
import ResetPasswordVerify from '@pages/reset-password-verify'
import RedirectHandler from '@components/RedirectHandler'

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    privateRoute: true,
  },
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/start',
    component: LoginPage,
  },
  {
    path: '/terms',
    component: TermsPage,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
  },
  {
    path: '/select-account',
    component: SelectAccountPage,
  },
  {
    path: '/report-generating/:reportToken',
    component: GeneratingPage,
  },
  {
    path: '/select-sample-account',
    component: SelectSampleAccountPage,
  },
  {
    path: '/auth/google/callback',
    component: RedirectHandler,
  },
  {
    path: '/lead-form',
    component: LeadFormPage,
    privateRoute: true,
  },
  {
    path: '/generating/:reportToken',
    component: GeneratingPage,
  },
  {
    path: '/report/:reportToken',
    component: ReportPage,
  },
  {
    path: '/report-demo',
    component: ReportDemoPage,
  },
  {
    path: '/ads-report/:reportToken',
    component: ReportPage,
  },
  {
    path: '/ads-reports/:reportToken',
    component: ReportPage,
  },
  {
    path: '/ad-report/:reportToken',
    component: ReportPage,
  },
  {
    path: '/debug',
    component: CodePage,
  },
  {
    path: '/login',
    component: LoginRoute,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/settings',
    component: Settings,
    privateRoute: true,
  },
  {
    path: '/dashboard/:reportToken',
    component: Dashboard,
    privateRoute: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/accounts/password-reset-confirm/:uid/:token',
    component: PasswordReset,
  },
  {
    path: '/generate-password/',
    component: GeneratePassword,
  },
  {
    path: '/accounts/email-verify',
    component: AcceptLoginInvite,
  },
  {
    path: '/accounts/verify-reset-email',
    component: ResetPasswordVerify,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },

  {
    path: '/accounts/accept-invite/:inviteToken',
    component: AcceptInvite,
  },
  {
    path: '/boards/share-board/public/:inviteToken',
    component: SharedBoards,
  },
  {
    path: '/report-dashboard',
    component: ReportDashboard,
    privateRoute: true,
  },
  {
    path: '/dashboard/calculator',
    component: () => Calculator,
    privateRoute: true,
  },
  {
    path: '/dashboard/benchmarks',
    component: () => Benchmark,
    privateRoute: true,
  },
  {
    path: '/dashboard/learning-center',
    component: () => LearningCenter,
    privateRoute: true,
  },
  {
    path: '/dashboard/learning-center/explore-more',
    component: () => KnowledgeAndGuides,
    privateRoute: true,
  },
  {
    path: '/dashboard/learning-center/search-feature',
    component: () => SearchFeature,
    privateRoute: true,
  },
  {
    path: '/dashboard/boards/detail',
    component: () => BoardsDetail,
    privateRoute: true,
  },
  {
    path: '/discover-ads-landing-page',
    component: UnauthenticatedDiscoverAds,
  },
  {
    path: '/dashboard/brands/detail',
    component: () => BrandsDetail,
    privateRoute: true,
  },
  {
    path: '/:path*',
    component: NotFound,
  },
]

export default routes
