import React, { useRef } from 'react'
import { Select } from 'antd'
import Text from '@components/Text/text'

const SearchBoard = ({ handleSignUpModal }) => {
  const selectRef = useRef(null)

  return (
    <div style={{ marginTop: 12 }} id="inputContainerModal">
      <Text style={{ fontSize: 14, color: '#67696B' }}>Add this ad to board</Text>
      <div style={{ marginTop: 12 }}>
        <Select
          open={false}
          ref={selectRef}
          placeholder="Select board(s)"
          onClick={handleSignUpModal} // Handle changes and update state
          style={{ width: '100%' }}
          showSearch={false} // Disables typing
          defaultActiveFirstOption={false} // Prevents automatic hovering on the first option
        />
      </div>
    </div>
  )
}

export default SearchBoard
