import React, { useState } from 'react'
import { Card, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import { Text } from '@components'

import styles from './styles.module.css'

const CodePage = () => {
  const [code, setCode] = useState('')
  const { t } = useTranslation()

  const onFailure = e => console.info(e)

  const handlLoginSuccess = async payload => {
    // eslint-disable-next-line no-console
    // eslint-disable-next-line no-alert
    setCode(payload.code)
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginHeader}>
        <Text className={styles.title} size={40}>
          Code:
        </Text>

        <input type="text" className={styles.input} value={code} />

        <br />
        <br />
        <Button type="primary" onClick={handlLoginSuccess}>
          {t('login.siginWithGoogle')}
        </Button>
      </div>
    </div>
  )
}

export default CodePage
