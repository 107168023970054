import React, { useEffect } from 'react'

const RedirectHandler = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const authCode = params.get('code')
    if (authCode) {
      // Send code back to the parent window
      window.opener?.postMessage({ code: authCode }, window.location.origin)
      // Close the popup window
      window.close()
    }
  }, [])

  return <></>
}

export default RedirectHandler
