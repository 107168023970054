import React, { useEffect, useRef, useState } from 'react'
import { Text } from '@components'
import { Select, Spin, Tabs, Checkbox } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import AdsCard from '@components/DiscoverAds/AdsCard'
import { RequestService } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import FilterArrow from '@images/DiscoverAds/SearchBar/filterArrow.png'
import {
  SAVED_PAGE_ALL_DATA,
  SAVED_PAGE_FILTER_ALL_DATA,
  SAVED_PAGE_UNIQUE_TAGS,
  API_DATA_BOARDS_LIST,
  ADS_CHANNEL,
  FOLLOWED_BRANDS_LIST,
} from '@utils/constants'
import styles from './saveAds.module.css'

const { TabPane } = Tabs

const SaveAds = () => {
  const filteredDiscoverDataOriginal = useSelector(
    state => state?.discoverAds?.filteredDiscoverData
  )
  const [filteredDiscoverData, setFilteredDiscoverData] = useState(filteredDiscoverDataOriginal)
  const dispatch = useDispatch()
  const triggerUpdateMe = useSelector(state => state?.discoverAds?.triggerUpdateMe)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [boardsList, setBoardsList] = useState(apiDataBoardsList)
  const [loading, setLoading] = useState(false)
  const [isRotated, setIsRotated] = useState(false)
  const [activeKey, setActiveKey] = useState('2')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [filterApplied, setFilterApplied] = useState(false)
  const dataRef = useRef()
  const allAdsData = useSelector(state => state?.discoverAds?.allAdsData)
  const uniqueTags = useSelector(state => state?.discoverAds?.uniqueTags)
  const filterClick = () => {
    setIsFilterActive(!isFilterActive)
    setIsRotated(!isRotated)
  }

  const handleTagChange = values => {
    setSelectedTags(values)
    setFilterApplied(values.length > 0)

    const filteredAds =
      values.length > 0
        ? allAdsData.filter(item => item.tags && item.tags.some(tag => values.includes(tag)))
        : allAdsData

    dispatch({ type: SAVED_PAGE_FILTER_ALL_DATA, payload: filteredAds })
  }

  const handleClearFilter = () => {
    setSelectedTags([])
    setFilterApplied(false)
    dispatch({ type: SAVED_PAGE_FILTER_ALL_DATA, payload: allAdsData })
  }

  const getSavedAds = async () => {
    setLoading(true)
    try {
      const response = await RequestService.get('/save/ads/')
      const ads = response?.data?.meta_ads || []

      dispatch({ type: SAVED_PAGE_ALL_DATA, payload: ads })
      dispatch({ type: SAVED_PAGE_FILTER_ALL_DATA, payload: ads })

      const tagsSet = new Set()
      ads.forEach(item => {
        if (item.tags && Array.isArray(item.tags)) {
          item.tags.forEach(tag => {
            tagsSet.add(tag)
          })
        }
      })
      dispatch({ type: SAVED_PAGE_UNIQUE_TAGS, payload: [...tagsSet] })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setBoardsList(response?.data)
    } catch (error) {
      // console.log('🚀 ~ handleDiscoverAdsBoard ~ error:', error)
    }
  }

  const handleShowModal = index => {
    setSelectedIndex(index)
  }

  const handlePrevious = () => {
    setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : filteredDiscoverData.length - 1))
  }

  const handleNext = () => {
    setSelectedIndex(prevIndex => (prevIndex < filteredDiscoverData.length - 1 ? prevIndex + 1 : 0))
  }

  useEffect(() => {
    dispatch({ type: ADS_CHANNEL, payload: 'All' })
    getSavedAds()
    handleDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [])

  const getFollowedBrandsList = async () => {
    try {
      const response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
  }
  const triggerUpdateFollowBrands = async () => {
    let response = []
    try {
      response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
    if (Array.isArray(dataRef.current)) {
      dataRef.current.forEach(ad => {
        const isFollowed = response?.data?.data.some(
          brand =>
            brand.google_advertiser_id === ad?.advertiser_id ||
            brand.meta_page_id === ad?.additional_info?.page_id
        )
        // eslint-disable-next-line
        ad.is_followed = isFollowed
      })
      setFilteredDiscoverData([...dataRef.current])
    }
  }

  useEffect(() => {
    triggerUpdateFollowBrands()
    // eslint-disable-next-line
  }, [triggerUpdateMe])

  useEffect(() => {
    getFollowedBrandsList()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (filteredDiscoverDataOriginal.length > 0) {
      dataRef.current = filteredDiscoverDataOriginal
      setFilteredDiscoverData(filteredDiscoverDataOriginal)
    }
    // eslint-disable-next-line
  }, [filteredDiscoverDataOriginal])
  return (
    <div>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 150 }}>
          <Spin />
        </div>
      ) : (
        <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }} id="uniqueTag">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text className={styles.title}>Saved Ads</Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                mode="multiple"
                placeholder="Sort by: Tags"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  width: '200px',
                  minHeight: 44,
                  marginLeft: '16px',
                  borderRadius: '2px',
                  color: '#202020',
                }}
                className="group-by-select"
                getPopupContainer={() => document.getElementById('uniqueTag')}
                dropdownClassName="group-by-dropdown"
                onChange={handleTagChange}
                value={selectedTags}
                maxTagCount="responsive"
                open={isFilterActive}
                onDropdownVisibleChange={filterClick}
                dropdownRender={menu => (
                  <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {' '}
                    {/* Add fixed height and scroll */}
                    {uniqueTags.length === 0 ? (
                      <Text style={{ padding: '8px 12px', color: '#C7C7C7', textAlign: 'center' }}>
                        No tags added yet
                      </Text>
                    ) : (
                      uniqueTags.map((tag, index) => (
                        // eslint-disable-next-line
                        <div
                          // eslint-disable-next-line
                          key={index}
                          style={{ padding: '8px 12px', cursor: 'pointer' }}
                          onClick={e => e.stopPropagation()} // Prevents div click from triggering checkbox click
                        >
                          <Checkbox
                            checked={selectedTags.includes(tag)}
                            onChange={() => {
                              const isChecked = selectedTags.includes(tag)
                              const newSelectedTags = isChecked
                                ? selectedTags.filter(t => t !== tag)
                                : [...selectedTags, tag]
                              handleTagChange(newSelectedTags)
                            }}
                          >
                            {tag}
                          </Checkbox>
                        </div>
                      ))
                    )}
                  </div>
                )}
              />

              <DownOutlined
                style={{
                  color: '#C7C7C7',
                  marginLeft: '-30px',
                  cursor: 'pointer',
                  transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
                onClick={filterClick}
              />
              {filterApplied && (
                <Text onClick={handleClearFilter} className={styles.clearTag}>
                  Clear
                </Text>
              )}
            </div>
          </div>
          <Tabs style={{ marginTop: 12 }} defaultActiveKey="1" onChange={key => setActiveKey(key)}>
            <TabPane
              tab={
                <span
                  style={{
                    letterSpacing: '1px',
                    color: activeKey === '2' ? '#26282C' : '#8F8F8F',
                  }}
                  className={styles.tabTitle}
                >
                  My Ads ({filteredDiscoverData.length})
                </span>
              }
              key="2"
            />
          </Tabs>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
              <Spin />
            </div>
          )}

          {filteredDiscoverData?.length > 0 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: 2,
                1400: 4,
                1800: 4,
                2200: 6,
                2500: 7,
              }}
            >
              <Masonry columnsCount={3} gutter="20px">
                {filteredDiscoverData.map((item, index) => (
                  <AdsCard
                    key={item.id || index}
                    adsData={item}
                    // eslint-disable-next-line
                    isSavedInSavedAds={true}
                    onShowModal={() => handleShowModal(index)}
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                    selectedData={filteredDiscoverData[selectedIndex]}
                    isFirst={selectedIndex === 0}
                    isLast={selectedIndex === filteredDiscoverData.length - 1}
                    boardsList={boardsList}
                    savedAdsFlow="true"
                    savedAdsFlowRoute="true"
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <Text size={24} isBold style={{ marginTop: 40, textAlign: 'center', color: '#f16434' }}>
              No Ads Found.
            </Text>
          )}
        </div>
      )}
    </div>
  )
}

export default SaveAds
