import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Menu, Modal, Row, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import {
  FILTERED_TAGS,
  TAGS,
  SCORE_AND_INDUSTRY,
  SCORE_AND_INDUSTRY_LOADER,
  RELATED_QUERIES,
  RELATED_QUERIES_LOADER,
  FOLLOW_BRANDS_LOADER,
  BRAND_STATUS,
  BRAND_STATUS_LOADER,
  ADS_DATA,
  ADS_HOME_STATE,
  ADS_LOADER,
  ADS_NO_DATA,
  ADS_PAGINATION,
  ADS_SEARCH_KEYWORD,
  CLEAR_BRANDS_FLITER,
  HAS_MORE,
  IS_GOOGLE_STATUS,
  IS_META_STATUS,
  SPINNER,
  TEMP_COUNT,
  EMPTY_ADS_PREVIEW_DATA,
  CLEAR_STATE,
  INPUT_VALUES,
  LIVE_SEARCH,
  CHECKED_VALUES,
  BRAND_CHOSEN,
  IN_ACTIVE_ADS_COUNT,
  ACTIVE_ADS_COUNT,
  ACTIVE_KEY,
  MIX_GOOGLE_PAGINATION,
  TRIGGER_UPDATE_FOLLOW_BRANDS,
  MIX_META_PAGINATION,
} from '@utils/constants'
import { notify } from '@utils/notify'
import { RequestService, bucketBaseURL, axiosInstance } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Next from '@images/DiscoverAds/Modal/next.png'
import Previous from '@images/DiscoverAds/Modal/previous.png'
import style from './adsModal.module.css'
import ShareModal from './ShareModal'
import ModalCard from './ModalCard'
import AdDetailCard from './AdDetailCard'

const AdsModal = ({
  handleSignUpModal,
  modalOpen,
  handleOk,
  handleCancel,
  adsData,
  isFirst,
  isLast,
  boardsList,
}) => {
  const dispatch = useDispatch()
  const localDate = new Date().toLocaleDateString()
  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const searchKeyword = useSelector(state => state?.discoverAds?.inputValues)
  const [shouldHandleSearchKeyword, setShouldHandleSearchKeyword] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(modalOpen)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const getScoresAndIndustry = async id => {
    if (id?.additional_info?.ad_score) {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      const customResponse = {
        // eslint-disable-next-line
        id: id?._id,
        industry: id?.additional_info?.industry,
        reasoning: id?.additional_info?.reasoning,
        score: id?.additional_info?.ad_score,
      }
      dispatch({ type: SCORE_AND_INDUSTRY, payload: customResponse })
    } else {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: true })
      const data = {
        // eslint-disable-next-line
        ad_id: id?._id,
      }
      try {
        const response = await RequestService.post('ads-preview/open-ai-v1/', data)
        dispatch({ type: SCORE_AND_INDUSTRY, payload: response.data })
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      } catch (error) {
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      }
    }
  }

  const handleRelatedQueriesData = async () => {
    dispatch({ type: RELATED_QUERIES, payload: [] })
    dispatch({ type: RELATED_QUERIES_LOADER, payload: true })
    const targetDomain = adsData?.search_term
    try {
      const response = await RequestService.get(
        `ads-preview/competitors-v1/?search_term=${targetDomain}`
      )
      const formattedData = response?.data
      dispatch({ type: RELATED_QUERIES, payload: formattedData?.google_ads[0] })
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    } catch (error) {
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    // eslint-disable-next-line
    if (adsData?._id) {
      getScoresAndIndustry(adsData)
      if (currentPath !== '/dashboard/creative-library') {
        handleRelatedQueriesData()
      }
    }
    // eslint-disable-next-line
  }, [adsData])
  const showModal = () => {
    setIsModalOpen(true)
  }

  const showShareModal = () => {
    setIsShareModalOpen(true)
  }

  const handleShareOk = () => {
    setIsShareModalOpen(false)
  }

  const handleShareCancel = () => {
    setIsShareModalOpen(false)
  }

  useEffect(() => {
    if (searchKeyword && shouldHandleSearchKeyword) {
      dispatch({ type: ADS_HOME_STATE, payload: false })
      dispatch({ type: HAS_MORE, payload: false })
      setIsModalOpen(false)
      setShouldHandleSearchKeyword(false)
    }
    // eslint-disable-next-line
  }, [searchKeyword, shouldHandleSearchKeyword])

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width="100%"
      footer={null}
      borderRadius="12px 12px 0px 0px"
    >
      <div className={style.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={handleCancel}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={style.closeButton} />
        </button>
      </div>
      <div className={style.modalContainer}>
        <div className={style.header}>
          <div className={style.heading}>
            {adsDate === localDate ? (
              <>
                {adsData?.additional_info?.page_profile_picture_url ? (
                  <img
                    src={adsData?.additional_info?.page_profile_picture_url}
                    alt="profile-pic"
                    width={48}
                    height={48}
                    style={{ borderRadius: '48px' }}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {adsData?.additional_info?.ads_grader_page_profile_picture_url ? (
                  <img
                    src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_page_profile_picture_url}`}
                    alt="profile-pic"
                    width={48}
                    height={48}
                    style={{ borderRadius: '48px' }}
                  />
                ) : (
                  ''
                )}
              </>
            )}
            {/* eslint-disable-next-line */}
            <div className={style.modalTitle} onClick={handleSignUpModal}>
              <Text size={16} isBold className={style.pageName}>
                {adsData?.additional_info?.page_name}
              </Text>
            </div>

            <Button onClick={handleSignUpModal} className={style.follow_button}>
              <div>Follow</div>
            </Button>
          </div>
          <div className={style.headerButtonContainer}>
            {/* eslint-disable-next-line */}
            <img
              src={Previous}
              style={{
                cursor: isFirst ? 'not-allowed' : 'pointer',
                marginTop: 8,
                opacity: isFirst ? 0.5 : 1,
              }}
              height={32}
              alt="previous"
              onClick={handleSignUpModal}
            />
            {/* eslint-disable-next-line */}
            <img
              src={Next}
              style={{
                cursor: isLast ? 'not-allowed' : 'pointer',
                marginTop: 8,
                opacity: isLast ? 0.5 : 1,
              }}
              height={32}
              alt="next"
              onClick={handleSignUpModal}
            />
          </div>
        </div>
        <Row style={{ marginTop: 32 }} gutter={32}>
          {/* Left Part of Modal */}
          <Col span={12}>
            <ModalCard adsData={adsData} handleSignUpModal={handleSignUpModal} />
          </Col>
          {/* Right Part of Modal */}
          <Col span={12}>
            <AdDetailCard
              adsData={adsData}
              boardsList={boardsList}
              handleSignUpModal={handleSignUpModal}
            />
          </Col>
        </Row>
      </div>
      {isShareModalOpen && (
        <ShareModal
          handleSignUpModal={handleSignUpModal}
          modalOpen={isShareModalOpen}
          handleOk={handleShareOk}
          handleCancel={handleShareCancel}
        />
      )}
    </Modal>
  )
}

export default AdsModal
