import { AG_AC_TOKEN } from '../utils/constants'
import { apiRoutes } from '../config/apiRoutes'
import api from './api'
import { RequestService } from './requests'

export const fetchCustomersList = async () => {
  const { url, method } = apiRoutes.customers
  try {
    const res = await api({
      url,
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
      },
    })

    const customerIds = res.data.map(customer => String(customer.id))
    const data = { customer_ids: customerIds }

    try {
      const counter = await RequestService.post('/customer-reports-count/', data)

      // Append counts to res.data
      res.data = res?.data?.map(customer => ({
        ...customer,
        reports_count: counter?.data[String(customer?.id)] || 0, // Handle missing counts
      }))
      return res
    } catch (error) {
      return res // Return original response even if count fetch fails
    }
  } catch (e) {
    return e.response
  }
}
