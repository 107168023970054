import React, { useCallback, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input } from 'antd'
import User from '@services/user'
import { baseURL, axiosInstance, RequestService } from '@services/requests'
import { fetchMetaUserInfo } from '@services/fetchMetaUserInfo'
import { notify } from '@utils/notify'
import Text from '@components/Text/text'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useFetch from 'use-http'
import { SecretService } from '@services/secret'
import { useTranslation } from 'react-i18next'
import { updateLocalStorage } from '@utils/helperFunctions'
import { useDispatch } from 'react-redux'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { fetchInvitersList } from '@services/fetchInvitersList'
import axios from 'axios'
import { useRollbar } from '@rollbar/react'
import { updateUserData, updateSelectedCustomer, updateErrorCustomer } from '../../store/actions'
import styles from './MetaEmailModal.module.css'

const MetaEmailLoginModal = ({ visible, onClose, onLogin, token, mainFlow }) => {
  const { post, response: loginResponse, loading } = useFetch(baseURL)
  const rollbar = useRollbar()
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const [metaEmaiilModal, setMetaEmailModal] = useState(false)
  const [metaAccessToken, setMetaAccessToken] = useState('')
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const handleOpenModal = () => {
    setMetaEmailModal(true)
  }

  const handleCloseModal = () => {
    setMetaEmailModal(false)
  }
  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
    // eslint-disable-next-line
  }, [])
  // Handle regular email login

  // Generate report for selected customer
  const retrieveReport = async selectedCustomer => {
    getProfile()
    getCountry()
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }
  // Handle successful customer selection and report generation
  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved, hasReport) => {
    try {
      if (!hasReport) {
        // Generate new report if user has report flag true
        const report = await retrieveReport(selectedCustomer)
        const reportPayload = {
          customerId: String(selectedCustomer.id),
          parentId: selectedCustomer.resource_name?.split('/')[1] || '',
          uuid: report.unique_id,
          currency: selectedCustomer.currency_code || 'USD',
          customerName: selectedCustomer.descriptive_name,
        }
        const reportToken = SecretService.encode(reportPayload)

        localStorage.setItem('selectAccount', JSON.stringify(reportToken))
        history.push(`/report-generating/${reportToken}`, { report })
      } else {
        // Don't generate report if has_report is false
        localStorage.setItem('selectAccount', JSON.stringify(selectedCustomer))
      }

      // After report handling, check where to redirect based on is_lead_save
      if (!isLeadSaved) {
        history.push('/lead-form')
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }
  const handleNoCustomersFallback = async () => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      } else {
        history.push('/lead-form')
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }
  const handleLogin = async values => {
    // try {
    //   const data = {
    //     access_token: token,
    //     email: values?.email,
    //   }
    //   const access = await post('facebook/login/', data)
    //   if (loginResponse.ok) {
    //     User.set({ ...access })
    //     localStorage.setItem('profilePic', access?.user?.profile?.profile_picture)
    //     history.push('/select-account')
    //     localStorage.setItem('loginThroughWebLink', JSON.stringify(true))
    //     onClose() // Close the modal after successful login
    //   }
    // } catch (error) {
    //   console.error(error)
    //   notify(t('notification.somethingWentWrong'), 'error')
    // } finally {
    //   setGoogleUserLoading(false)
    // }

    try {
      setMetaAccessToken(token.accessToken)
      setGoogleUserLoading(true)
      const data = {
        access_token: token,
        email: values?.email,
      }
      const res = await RequestService.post('facebook/login/', data)

      setGoogleUserLoading(false)

      if (res.status === 200) {
        const userData = { ...res.data, isGoogleUser: true }
        localStorage.setItem('profilePic', res?.data?.user?.profile?.profile_picture)
        notify(t('notification.loginSuccess'), 'success')
        updateLocalStorage(userData)
        dispatch(updateUserData(userData))

        // Redirect if report data exists in localStorage
        if (
          localStorage.getItem('copied_report_unique_id') &&
          localStorage.getItem('copied_report_customer_id')
        ) {
          history.push('/report-dashboard')
          return
        }

        const isLeadSaved = userData?.user?.is_lead_save
        localStorage.setItem('leadForm', JSON.stringify(isLeadSaved))

        if (isLeadSaved) {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
        } else {
          // Fetch customers list
          const customersResponse = await (async () => {
            const customersResponseUnSorted = await fetchCustomersList()
            return customersResponseUnSorted?.data?.length > 0
              ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
              : []
          })()

          if (customersResponse?.length > 0) {
            const selectedCustomer = customersResponse[0]
            dispatch(updateSelectedCustomer(selectedCustomer))
            localStorage.setItem('customer', JSON.stringify(selectedCustomer))

            // Pass both is_lead_save and has_report to handleCustomerSuccess
            await handleCustomerSuccess(
              selectedCustomer,
              userData?.user?.is_lead_save,
              userData?.user?.profile?.has_report
            )
          } else {
            await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
          }
        }
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
      }
    } catch (error) {
      console.error('Google login error:', error)
      notify(t('notification.somethingWentWrong'), 'error')
    } finally {
      setGoogleUserLoading(false)
    }
  }

  return (
    <Modal
      title={
        <>
          <div
            style={{
              textAlign: 'center',
              marginTop: 30,
              fontSize: 24,
              fontWeight: '600',
            }}
          >
            We Need Your Email Address
          </div>
          <div className={styles.divider} />
        </>
      }
      visible={visible}
      footer={null}
      centered
    >
      <Text style={{ display: 'block', marginBottom: 20, textAlign: 'center' }}>
        It seems your Facebook account is registered with a mobile number. To proceed, please
        provide your email address.
      </Text>
      <div className={styles.crossButton}>
        <Button
          type="submit"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={onClose}
        >
          <CloseOutlined className={styles.closeButton} />
        </Button>
      </div>
      <Form name="loginForm" layout="vertical" onFinish={handleLogin}>
        <div style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 5 }}>
          Email <span className={styles.requiredAsterisk}>*</span>
        </div>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block className={styles.searchButton}>
            Log In
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MetaEmailLoginModal
