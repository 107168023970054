import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import Text from '@components/Text/text'
import { RequestService } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import {
  SCORE_AND_INDUSTRY,
  SCORE_AND_INDUSTRY_LOADER,
  RELATED_QUERIES,
  RELATED_QUERIES_LOADER,
} from '@utils/constants'
import SearchBoard from './SearchBoard'
import Actions from './Actions'
import MagicAI from './MagicAI'
import AdInfo from './AdInfo'
import Tags from './Tags'
import RelatedQueries from './RelatedQueries'
import styles from './adDetailCard.module.css'

const { TabPane } = Tabs

const AdDetailCard = ({ adsData, boardsList, handleSignUpModal }) => {
  const searchKeyword = useSelector(state => state?.discoverAds?.searchKeyword)
  const dispatch = useDispatch()
  const [activeKey, setActiveKey] = useState('1')
  const getScoresAndIndustry = async () => {
    if (adsData?.additional_info?.ad_score) {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      const customResponse = {
        // eslint-disable-next-line
        id: adsData?._id,
        industry: adsData?.additional_info?.industry,
        reasoning: adsData?.additional_info?.reasoning,
        score: adsData?.additional_info?.ad_score,
      }
      dispatch({ type: SCORE_AND_INDUSTRY, payload: customResponse })
    } else {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: true })
      const data = {
        // eslint-disable-next-line
        ad_id: adsData._id,
      }
      try {
        const response = await RequestService.post('ads-preview/open-ai-v1/', data)
        dispatch({ type: SCORE_AND_INDUSTRY, payload: response.data })
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      } catch (error) {
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      }
    }
  }
  const handleRelatedQueriesData = async () => {
    dispatch({ type: RELATED_QUERIES, payload: [] })
    dispatch({ type: RELATED_QUERIES_LOADER, payload: true })
    const targetDomain = adsData?.search_term
    try {
      const response = await RequestService.get(
        `ads-preview/competitors/v1/?search_term=${searchKeyword}`
      )
      const formattedData = response?.data
      dispatch({ type: RELATED_QUERIES, payload: formattedData?.google_ads[0] })
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    } catch (error) {
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    }
  }
  useEffect(() => {
    getScoresAndIndustry()
    handleRelatedQueriesData()
    // eslint-disable-next-line
  }, [])
  return (
    <Card style={{ background: '#F9FAFB', border: '1px solid #E3E7EA' }}>
      <Tabs defaultActiveKey="1" onChange={key => setActiveKey(key)}>
        <TabPane
          tab={
            <Text
              isBold
              size={14}
              style={{
                color: activeKey === '1' ? '#26282C' : '#8F8F8F',
              }}
              className={styles.tabTitle}
            >
              Details
            </Text>
          }
          key="1"
        >
          <Tags adsData={adsData} handleSignUpModal={handleSignUpModal} />
          <SearchBoard handleSignUpModal={handleSignUpModal} />
          <Actions adsData={adsData} handleSignUpModal={handleSignUpModal} />
          <MagicAI adsData={adsData} />
          <AdInfo adsData={adsData} />
          <RelatedQueries adsData={adsData} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default AdDetailCard
