import { SearchOutlined } from '@ant-design/icons'
import { Input, Tag } from 'antd'

import Text from '@components/Text/text'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TAGS, FILTERED_TAGS } from '@utils/constants'

const Tags = ({ adsData, handleSignUpModal }) => {
  // eslint-disable-next-line

  const dispatch = useDispatch()
  const tags = useSelector(state => state?.discoverAds?.tags)
  const originalTags = tags
  const filteredTags = useSelector(state => state?.discoverAds?.filteredTags)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = e => {
    const value = e.target.value.toLowerCase()
    setInputValue(value)
    // if (!value) {
    dispatch({ type: TAGS, payload: originalTags })
    dispatch({
      type: FILTERED_TAGS,
      payload: originalTags,
    })
  }

  return (
    <div style={{ border: '1px solid #DFE3E4', background: 'white' }}>
      <div style={{ padding: 24 }}>
        <Text isBold>Tags</Text>
        <Input
          placeholder="Search or create tags"
          prefix={<SearchOutlined />}
          style={{ marginTop: 12 }}
          value={inputValue}
          onChange={handleInputChange}
          onPressEnter={handleSignUpModal}
        />
        <div style={{ marginTop: 18 }}>
          {filteredTags?.map(tag => (
            <Tag
              key={tag?.id}
              style={{ borderRadius: 48, padding: '10px', marginTop: '5px' }}
              closable
              onClose={handleSignUpModal}
            >
              {tag.name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tags
