import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Divider, message } from 'antd'
import EmailLoginPage from '@components/EmailLogin/loginPage'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import backgroundImage from '@images/leadFormBg.png'
import styles from './authentication.module.css'
import FacebookSSOForLoginFlow from './FacebookSSO'

const showField = (formik, key) => Object.keys(formik.values).includes(key)
const inviteFromString = localStorage.getItem('inviteFrom')
const inviteFrom = JSON?.parse(inviteFromString)
const isBoardPrivate = inviteFrom?.isBoardPrivate

const Authentication = ({
  componentType,
  formik,
  isLoading,
  handleGoogleLogin,
  title,
  description,
  buttonText,
}) => {
  const { getFieldProps } = formik
  const [disableGoogleButton, setDisableGoogleButton] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const onFailure = e => {
    // if (e.error !== 'popup_closed_by_user') {
    //   setDisableGoogleButton(true)
    //   notify(e.details, 'error')
    // }
  }
  useEffect(() => {
    const handleEvent = event => {
      if (event.key === 'Enter') {
        formik.handleSubmit()
      }
    }

    document.addEventListener('keydown', handleEvent)

    return () => {
      document.removeEventListener('keydown', handleEvent)
    }
    // eslint-disable-next-line
  }, [])
  const getUniqueIDFromURL = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const reportId = url.searchParams.get('reportId')
    const customerId = url.searchParams.get('customerId')
    return { reportId, customerId }
  }

  useEffect(() => {
    const user = localStorage.getItem('np_ads_grader:session')
    const userObject = JSON.parse(user)
    const userEmail = userObject?.user.email
    const accessToken = localStorage.getItem('adsGraderAccessToken')
    const inviteForm = localStorage.getItem('inviteFrom')
    const jsonObject = JSON.parse(inviteForm)
    const inviteFormEmail = jsonObject?.email
    if (accessToken) {
      if (userEmail !== inviteFormEmail && isBoardPrivate) {
        // message.info('Please login to authorized account to see the board.')
        message.open({
          type: 'info',
          content: 'Please login to authorized account to see the board.',
          duration: 10, // Keep it open until manually closed
          style: {
            marginTop: '8.5vh',
          },
        })
        history.push('/dashboard/boards')
      } else {
        // eslint-disable-next-line
        if (userEmail === inviteFormEmail) {
          const { reportId, customerId } = getUniqueIDFromURL()
          if ((reportId, customerId)) {
            localStorage.setItem('copied_report_unique_id', reportId)
            localStorage.setItem('copied_report_customer_id', customerId)
          }
          // history.push('/report-dashboard')
          history.push('/dashboard')
        } else if (
          userEmail?.includes('npaccel.com') ||
          userEmail?.includes('npdigital.com') ||
          userEmail?.includes('neilpatel.com') ||
          userEmail?.includes('ubersuggest.com') ||
          userEmail?.includes('grayphite.com')
        ) {
          const { reportId, customerId } = getUniqueIDFromURL()
          if ((reportId, customerId)) {
            localStorage.setItem('copied_report_unique_id', reportId)
            localStorage.setItem('copied_report_customer_id', customerId)
          }

          history.push('/report-dashboard')
        } else {
          //  eslint-disable-next-line
          if (
            userEmail?.includes('npaccel.com') ||
            userEmail?.includes('npdigital.com') ||
            userEmail?.includes('neilpatel.com') ||
            userEmail?.includes('ubersuggest.com') ||
            userEmail?.includes('grayphite.com')
          ) {
            const { reportId, customerId } = getUniqueIDFromURL()
            if ((reportId, customerId)) {
              localStorage.setItem('copied_report_unique_id', reportId)
              localStorage.setItem('copied_report_customer_id', customerId)
              history.push('/report-dashboard')
            }
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [history])
  return (
    <div className={styles.backgroundContainer}>
      <img className={styles.backgroundImage} src={backgroundImage} alt="backgroundImage" />
      <div className={styles.loginRouteWrapper}>
        {description && <p>{description}</p>}
        <div className={styles.loginFormCard}>
          <form className={styles.loginForm} onSubmit={formik.handleSubmit}>
            {componentType !== 'forgotPassword' && (
              <>
                <div className={styles.googleButtonContainer}>
                  <button
                    type="button"
                    onClick={handleGoogleLogin}
                    disabled={disableGoogleButton}
                    className={styles.googleButton}
                    href="#continue-with-google"
                  >
                    <div className={styles.googleImageContainer}>
                      <img
                        src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png"
                        width={20}
                        height={20}
                        alt="google"
                      />
                    </div>
                    <span className={styles.googleButtonText}>{t('continueWithGoogle')}</span>
                  </button>
                </div>

                <FacebookSSOForLoginFlow />
              </>
            )}
          </form>
          <div style={{ marginTop: 30 }}>
            <Divider>OR</Divider>
          </div>
          <EmailLoginPage />
        </div>

        {componentType === 'register' && (
          <p className={styles.registerWrapper}>
            Already have an account?{' '}
            <span className={styles.forgotPassword}>
              <Link to="/login">Log in</Link>
            </span>
          </p>
        )}
        {componentType === 'forgotPassword' && (
          <p className={styles.registerWrapper}>
            <span className={styles.forgotPassword}>
              <Link to="/login">Back to Login</Link>
            </span>
          </p>
        )}
      </div>
    </div>
  )
}

export default Authentication
