export const AG_AC_TOKEN = 'adsGraderAccessToken'
export const USER = 'user'
export const LS_KEY = 'np_ads_grader:session'
export const UPDATE_USER = 'updateUser'
export const UPDATE_CUSTOMER = 'updateCustomer'
export const UPDATE_REPORT = 'updateReport'
export const LOGOUT_USER = 'logoutUser'
export const UPDATE_ROLE = 'role'
export const UPDATE_SAMPLE_REPORT = 'sampleReport'
export const UPDATE_REPORT_DASHBOARD = 'reportDashboardReducer'
export const UPDATE_ERROR_CUSTOMER = 'errorCustomer'
export const UPDATE_GENERATE_REPORT = 'generateReport'
export const UPDATE_CURRENT_REPORT = 'currentReport'
export const UPDATE_LEARNING_SEARCH_STRING = 'learningCenterSearch'
export const UPDATE_LEARNING_SELECTED_VALUE = 'learningCenterSelect'
export const SELECTED_ID = 'selectedId'
export const MANAGE_USERS_TAB = 'manageUsersTab'
export const ACCOUNT_TAB = 'accountTab'
export const PLATFORM_INTEGRATIONS = 'platformIntegrations'
export const USER_EMIAL = 'userEmail'
export const ADS_PERFORMANCE_GRADE = 'adsperformancegrade'
export const PERFORMANCE_SECTION = 'performanceSection'
export const COST_EVALUATION_SECTION = 'costEvaluationSection'
export const WASTED_SPEND_SECTION = 'wastedSpendSection'
export const AD_RELEVANCE_SECTION = 'adRelevanceSection'
export const QUALITY_SCORE_SECTION = 'qualityScoreSection'
export const CLICK_THROUGH_RATE_SECTION = 'clickThroughRateSection'
export const KEYWORDS_MATCH_TYPE_SECTION = 'keywordsMatchTypeSection'
export const IMPRESSION_SHARE_SECTION = 'impressionShareSection'
export const GOOGLE_ADS_BEST_PRACTICES_SECTION = 'googleAdsBestPracticesSection'
export const SHARE_WITH_TEAM_SECTION = 'shareWithTeamSection'
export const ADS_BEDGET_COST_CACULATOR = 'adsBudgetCostCalculator'
export const AG_ALERTS = 'agAlert'
export const ADS_SEARCH_COMPANY = 'adsPreviewSearchCompany'
export const ADS_SEARCH_KEYWORD = 'adsPreviewSearchKeyword'
export const ADS_START_DATE = 'adsPreviewStartDate'
export const ADS_END_DATE = 'adsPreviewEndDate'
export const ADS_LOCATION = 'adsPreviewLocation'
export const ADS_CHANNEL = 'adsPreviewChannel'
export const ADS_FORMAT = 'adsPreviewFormat'
export const ADS_DATA = 'adsPreviewData'
export const ADS_DATA_FOR_GOOGLE = 'adsPreviewDataForGoogle'
export const ADS_DATA_FOR_META = 'adsPreviewDataForMeta'
export const ADS_PAGINATION_DATA = 'adsPaginationData'
export const EMPTY_ADS_PREVIEW_DATA = 'emptyAdsPreviewData'
export const EMPTY_ADS_PAGINATION_DATA = 'emptyPaginationData'
export const ADS_LOADER = 'adsPreviewLoader'
export const ADS_LOADER_FOR_GOOGLE_ALL_CHANNEL = 'adsLoaderForGoogleAllChannel'
export const ADS_LOADER_FOR_META_ALL_CHANNEL = 'adsLoaderForMetaAllChannel'
export const ADS_HOME_STATE = 'adsHomeState'
export const ADS_NO_DATA = 'adsNoData'
export const ADS_NO_DATA_FOR_GOOGLE = 'adsNoDataForGoogle'
export const ADS_NO_DATA_FOR_META = 'adsNoDataForMeta'
export const ADS_PAGINATION = 'adsPagination'
export const ADS_PAGINATION_FOR_META_ALL_CHANNEL = 'adsPaginationForMetaAllChannel'
export const ADS_PAGINATION_FOR_GOOGLE_ALL_CHANNEL = 'adsPaginationForGoogleAllChannel'
export const ADS_MODAL_PAGINATION = 'adsModalPagination'
export const LEARNING_CENTER = 'learningCenter'
export const LEARNING_CENTER_MESSAGE_FROM_MODAL = 'learningCenterMessageFromModal'
export const LEARNING_CENTER_API_DATA = 'learningCenterApiData'
export const LEAD_DATA = 'leadData'
export const UPDATE_ACTIVE_BUTTON_STATE = 'update_active_button_state'
export const FILTERED_DATA_LENGTH = 'filtered_data_length'
export const HAS_MORE = 'has_more'
export const HAS_MORE_FOR_META_ALL_CHANNEL = 'has_more_for_meta_all_channel'
export const HAS_MORE_FOR_GOOGLE_ALL_CHANNEL = 'has_more_for_google_all_channel'
export const PAGE_COUNT = 'page_count'
export const PAGE_COUNT_RESET = 'page_count_reset'
export const SPINNER = 'spinner'
export const CLEANED_DATA_COUNT = 'cleaned_data_count'
export const CURRENT_REPORT_ID = 'current_report_id'
export const MORE_DATA_LOADER = 'moreDataLoader'
export const META_ADS_PAGINATION_DATA = 'meta_ads_pagination_data'
export const WEEKLY_REPORT = 'weeklyReport'
export const REPORT_DIFFERENCE = ' reportDifference'
export const SINGLE_REPORT = 'singleReport'
export const REPORT_INDEX = 'reportIndex'
export const ALL_REPORTS = 'allReports'
export const PAGINATED_REPORTS = 'paginatedreports'
export const PAGINATED_NEXT = 'paginatedNext'
export const REPORT_CURRENT_ID = 'report_current_id'
export const EXTRA_INSIGHTS_CLICK = 'extra_insights_click'
export const EXTRA_INSIGHTS_DATA = 'extra_insights_data'
export const ADD_BOARD = 'add_board'
export const SET_BOARDS = 'set_boards'
export const SAVE_AD_TO_BOARD = 'save_ad_to_board'
export const RENAME_BOARD = 'rename_board'
export const DELETE_BOARD = 'delete_board'
export const BOARDS_SEARCH_KEYWORD = 'boards_search_keyword'
export const CLEAR_STATE = 'clear_state'
export const TEMP_COUNT = 'temp_count'
export const BOARD_DETAIL_DATA = 'board_detail_data'
export const ADS_DATA_FOR_NO_DATA_FOUND = 'ads_data_fot_no_data_found'
export const BOARD_SEARCH = 'board_search'
export const BOARDS_LIST = 'boards_list'
export const BOARDS_LIST_COUNT = 'boards_list_count'
export const TAGS = 'tags'
export const FILTERED_TAGS = 'filtered_tags'
export const CLEAR_BRANDS_FLITER = 'clear_brands_filter'
export const BOARD_LIST = 'board_list'
export const BOARDS_TAG_FILTER = 'boards_tag_filter'
export const SAVED_PAGE_ALL_DATA = 'saved_page_all_data'
export const SAVED_PAGE_FILTER_ALL_DATA = 'saved_page_filter_all_data'
export const SAVED_PAGE_UNIQUE_TAGS = 'saved_page_unique_tags'
export const API_DATA_BOARDS_LIST = 'api_data_baords_list'
export const SCORE_AND_INDUSTRY_LOADER = 'score_and_industry_loader'
export const SCORE_AND_INDUSTRY = 'score_and_industry'
export const RELATED_QUERIES = 'related_queries'
export const RELATED_QUERIES_LOADER = 'related_queries_loader'
export const SHARED_BOARDS = 'shared_boards'
export const SHARED_AND_PRIVATE_BOARDS_COUNT = 'shared_and_private_boards_count'
export const ACTIVE_KEY = 'active_key'
export const SAVED_DESCRIPTION = 'saved_description'
export const INVITED_BOARDS_USERS = 'invited_boards_users'
export const SEARCH_SUGGESTIONS = 'search_suggestions'
export const IS_META_STATUS = 'is_meta_status'
export const IS_GOOGLE_STATUS = 'is_google_status'
export const FETCH_SPECTFIC_BOARD_DATA = 'fetch_specific_board_data'
export const FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT =
  'fetch_spectfic_board_data_for_tags_and_count'
export const SPECIFIC_BRAND_DETAIL = 'specific_brand_detail'
export const BRAND_CHOSEN = 'brand_chosen'
export const INPUT_VALUES = 'input_values'
export const BRAND_STATUS = 'brand_status'
export const BRAND_STATUS_LOADER = 'brand_status_loader'
export const FOLLOW_BRANDS_LOADER = 'follow_brands_loader'
export const MIX_GOOGLE_PAGINATION = 'mix_google_pagination'
export const MIX_META_PAGINATION = 'mix_meta_pagination'
export const CHECKED_VALUES = 'chedked_values'
export const BRANDS_TAGS_DATA = 'brands_tags_data'
export const BRANDS_TAGS_FOR_GOOGLE = 'brands_tag_for_google'
export const BRANDS_TAGS_FOR_META = 'brands_tag_for_meta'
export const PAGE_ID_AND_ADVERTISER_ID = 'page_id_and_advertiser_id'
export const TRIGGER_SEARCH = 'trigger_search'
export const BRANDS_LIST = 'brands_list'
export const ACTIVE_ADS_COUNT = 'active_ads_count'
export const IN_ACTIVE_ADS_COUNT = 'in_active_ads_count'
export const LIVE_SEARCH = 'live_search'
export const BRAND_TAG_FOR_ALL = 'brand_tag_for_all'
export const BRAND_TAG_FOR_GOOGLE = 'brand_tag_for_google'
export const BRAND_TAG_FOR_META = 'brand_tag_for_meta'
export const CLEAR_BRANDS_TAG_FLAG = 'clear_brands_tag_flag'
export const GOOGLE_BRANDS_LIST = 'google_brands_list'
export const META_BRANDS_LIST = 'meta_brands_list'
export const ALL_BRANDS_LIST = 'all_brands_list'
export const BRAND_OPTIONS = 'brand_options'
export const FOLLOWED_BRANDS_LIST = 'followed_brands_list'
export const TRIGGER_UPDATE_FOLLOW_BRANDS = 'trigger_update_follow_brands'
export const LANDING_PAGE_DATA = 'landing_page_data'

// start of Un-Auth discover Ads

export const UN_AUTH_ACTIVE_KEY = 'un_auth_active_key'
export const UN_AUTH_ADS_CHANNEL = 'un_auth_ads_channel'
export const UN_AUTH_LOCATION = 'un_auth_location'
export const UN_AUTH_FORMATS = 'un_auth_formats'
export const SEARCH_HISTORY = 'search_history'
// end of Un-Auth discover Ads
export const BRAND_CHOSEN_ID = 'brand_chosen_id'
export const META_OPTIONS = 'meta_options'
export const SEARCH_SUGGESTIONS_FOR_BRANDS = 'search_suggestions_for_brands'
export const MEDIA_TYPE = 'media_type'
export const API_HITS_COUNT = 'api_hits_count'
