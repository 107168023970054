import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { Spin } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { updateLocalStorage } from '@utils/helperFunctions'
import { fetchInvitersList } from '@services/fetchInvitersList'
import { notify } from '@utils/notify'
import { SecretService } from '@services/secret'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { useRollbar } from '@rollbar/react'
import { RequestService, axiosInstance } from '@services/requests'
import Modal from '../../components/Modal'
import { apiRoutes } from '../../config/apiRoutes'
import Authentication from '../../components/Authentication'
import { updateUserData, updateErrorCustomer, updateSelectedCustomer } from '../../store/actions'
import styles from './register.module.css'

const googleClientId = process?.env?.REACT_APP_GOOGLE_PROJECT_CLIENT_ID
const validationSchema = yup.object().shape({
  first_name: yup.string().required().min(3).label('First Name'),
  last_name: yup.string().required().min(3).label('Last Name'),
  email: yup.string().email('Email is invalid!').required().label('Email'),
  password: yup.string().min(8).required().label('Password'),
  receive_emails: yup.boolean().oneOf([true], 'Pleaase check the email notification checkbox.'),
  terms_and_conditions: yup
    .boolean()
    .oneOf([true], 'Please check the terms and conditons checkbox.'),
})

const Register = props => {
  // eslint-disable-next-line
  const rollbar = useRollbar()
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const invitedUser = props?.location?.state?.invitedUser
  const alreadyLoggedIn = localStorage.getItem('adsGraderAccessToken')

  const [isLoading, setIsLoading] = useState(false)
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    // eslint-disable-next-line
    if (!invitedUser && !alreadyLoggedIn) {
      history.push('/login')
    } else if (!invitedUser && alreadyLoggedIn) {
      history.push('/dashboard/creative-library')
    }
    // eslint-disable-next-line
  }, [invitedUser, alreadyLoggedIn])
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      terms_and_conditions: false,
      receive_emails: false,
    },
    validationSchema,
    onSubmit: async values => {
      const { url, method } = apiRoutes.register
      const updatedValues = {
        email: values.email,
        password1: values.password,
        password2: values.password,
        is_email_notification: values.receive_emails,
        first_name: values.first_name,
        last_name: values.last_name,
      }
      setIsLoading(true)
      try {
        const data = {
          username: values.first_name + values.last_name,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
        }
        const res = await axiosInstance.post('/accounts/email-register/', data)

        if (res.status === 201) {
          setIsLoading(false)
          if (res.data.access_token) {
            const userData = { ...res.data }
            updateLocalStorage(userData)
            dispatch(updateUserData(userData))
            history.push('/lead-form')
          } else {
            setShowModal(true)
          }
        }
      } catch (e) {
        setIsLoading(false)
        if (e?.response?.data?.email?.length) {
          notify(e.response.data.email[0], 'error')
        }
      }
    },
  })

  useEffect(() => {
    if (formik?.values?.email) {
      formik.setFieldTouched('email')
    }
    if (formik?.values?.password) {
      formik.setFieldTouched('password')
    }
    if (formik?.values?.first_name) {
      formik.setFieldTouched('first_name')
    }
    if (formik?.values?.last_name) {
      formik.setFieldTouched('last_name')
    }

    // eslint-disable-next-line
  }, [
    formik?.values?.email,
    formik?.values?.first_name,
    formik?.values?.last_name,
    formik?.values?.password,
  ])

  const handleCancel = () => {
    setShowModal(false)
    history.push('/')
  }
  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
    // eslint-disable-next-line
  }, [])
  const retrieveReport = async selectedCustomer => {
    getProfile()
    getCountry()
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }

  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved, hasReport) => {
    try {
      if (!hasReport) {
        // Generate new report if user has report flag true
        const report = await retrieveReport(selectedCustomer)
        const reportPayload = {
          customerId: String(selectedCustomer.id),
          parentId: selectedCustomer.resource_name?.split('/')[1] || '',
          uuid: report.unique_id,
          currency: selectedCustomer.currency_code || 'USD',
          customerName: selectedCustomer.descriptive_name,
        }
        const reportToken = SecretService.encode(reportPayload)

        localStorage.setItem('selectAccount', JSON.stringify(reportToken))
        history.push(`/report-generating/${reportToken}`, { report })
      } else {
        // Don't generate report if has_report is false
        localStorage.setItem('selectAccount', JSON.stringify(selectedCustomer))
      }

      // After report handling, check where to redirect based on is_lead_save
      if (!isLeadSaved) {
        history.push('/lead-form')
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }

  // Handle fallback when no customers found
  const handleNoCustomersFallback = async leadFormStatus => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      } else {
        // eslint-disable-next-line
        if (!leadFormStatus) {
          history.push('/lead-form')
        } else {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
        }
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }
  const handleFetchGoogleUserInfo = async (payload, redirectUri) => {
    setGoogleUserLoading(true)
    try {
      const res = await fetchGoogleUserInfo(payload, redirectUri)
      if (res.status === 200) {
        const userData = { ...res.data, isGoogleUser: true }
        localStorage.setItem('profilePic', userData?.user?.profile?.profile_picture)
        updateLocalStorage(userData)
        dispatch(updateUserData(userData))
        notify(t('notification.loginSuccess'), 'success')

        // Always generate a report upon successful login
        const customersResponse = await (async () => {
          const customersResponseUnSorted = await fetchCustomersList()
          return customersResponseUnSorted?.data?.length > 0
            ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
            : []
        })()

        if (customersResponse?.length > 0) {
          const selectedCustomer = customersResponse[0]
          dispatch(updateSelectedCustomer(selectedCustomer))
          localStorage.setItem('customer', JSON.stringify(selectedCustomer))

          // Pass both is_lead_save and has_report to handleCustomerSuccess
          await handleCustomerSuccess(
            selectedCustomer,
            userData?.user?.is_lead_save,
            userData?.user?.profile?.has_report
          )
        } else {
          await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
        }
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
      }
    } catch (error) {
      console.error('Google login error:', error)
      notify(t('notification.somethingWentWrong'), 'error')
    } finally {
      setGoogleUserLoading(false)
    }
  }
  const exchangeCodeForToken = async (authCode, redirectUri) => {
    handleFetchGoogleUserInfo(authCode, redirectUri)
  }
  const handleGoogleLoginSuccess = () => {
    const redirectUri = `${window.location.origin}/auth/google/callback`
    const scopes = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/adwords',
    ]

    const authParams = new URLSearchParams({
      response_type: 'code',
      access_type: 'offline',
      client_id: googleClientId,
      redirect_uri: redirectUri,
      scope: scopes.join(' '),
      prompt: '',
    })

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${authParams}`
    const authWindow = window.open(authUrl, '_blank', 'width=500,height=600,left=100,top=100')
    const messageHandler = event => {
      if (event.origin === window.location.origin) {
        const { code } = event.data
        if (code) {
          exchangeCodeForToken(code, redirectUri) // Step 3: Get access token
          authWindow?.close() // Close popup after getting the code
          window.removeEventListener('message', messageHandler) // Remove listener after handling
        }
      }
    }
    // Adding the event listener
    window.addEventListener('message', messageHandler)
  }
  return (
    <div className={styles.registerContainer}>
      {googleUserLoading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <>
          <Authentication
            componentType="register"
            formik={formik}
            isLoading={isLoading}
            buttonText="Register"
            handleGoogleLogin={handleGoogleLoginSuccess}
          />
          <Modal
            open={showModal}
            heading="Verification Email Sent Successfully"
            description="Please verify your email to use our services without any delay."
            buttonText="Back to Login"
            onCancel={handleCancel}
          />
        </>
      )}
    </div>
  )
}

export default Register
