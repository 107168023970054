// Main flow
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Spin, Space, Divider } from 'antd'
import { SecretService } from '@services/secret'
import { useTranslation } from 'react-i18next'
import useFetch from 'use-http'
import { fetchInvitersList } from '@services/fetchInvitersList'
import { useHistory } from 'react-router-dom'
import { baseURL, RequestService } from '@services/requests'
import { notify } from '@utils/notify'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { fetchCustomersList } from '@services/fetchCustomersList'
import EmailLoginPage from '@components/EmailLogin/loginPage'
import { updateLocalStorage } from '@utils/helperFunctions'
import SignInWithGoogleButton from '@components/SignInWithGoogleButton'
import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import { useDispatch } from 'react-redux'
import backgroundImage from '@images/leadFormBg.png'
import { useRollbar } from '@rollbar/react'
import axios from 'axios'
import Footer from '@components/Footer'

import { updateSelectedCustomer, updateUserData, updateErrorCustomer } from '../../store/actions'
import FacebookSSOForMainFlow from './SSOWithFaceook/SSO'
import styles from './login.module.scss'

const googleClientId = process?.env?.REACT_APP_GOOGLE_PROJECT_CLIENT_ID
const LoginPage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const rollbar = useRollbar()
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const history = useHistory()
  const { post, response: loginResponse, loading } = useFetch(baseURL)
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onFailure = e => console.info(e)

  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
  }, [rollbar])

  const retrieveReport = async selectedCustomer => {
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }

  // Handle successful customer selection and report generation
  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved, hasReport) => {
    try {
      if (!hasReport) {
        // Generate new report if user has report flag true
        const report = await retrieveReport(selectedCustomer)
        const reportPayload = {
          customerId: String(selectedCustomer.id),
          parentId: selectedCustomer.resource_name?.split('/')[1] || '',
          uuid: report.unique_id,
          currency: selectedCustomer.currency_code || 'USD',
          customerName: selectedCustomer.descriptive_name,
        }
        const reportToken = SecretService.encode(reportPayload)

        localStorage.setItem('selectAccount', JSON.stringify(reportToken))
        history.push(`/report-generating/${reportToken}`, { report })
      } else {
        // Don't generate report if has_report is false
        localStorage.setItem('selectAccount', JSON.stringify(selectedCustomer))
      }

      // After report handling, check where to redirect based on is_lead_save
      if (!isLeadSaved) {
        history.push('/lead-form')
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }
  const handleNoCustomersFallback = async leadFormStatus => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
      } else {
        // eslint-disable-next-line
        if (!leadFormStatus) {
          history.push('/lead-form')
        } else {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard')
        }
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }

  const handleFetchGoogleUserInfo = async (payload, redirectUri) => {
    localStorage.setItem('domain_flow', true)
    setGoogleUserLoading(true)
    try {
      const res = await fetchGoogleUserInfo(payload, redirectUri)
      if (res.status === 200) {
        const userData = { ...res.data, isGoogleUser: true }
        localStorage.setItem('profilePic', userData?.user?.profile?.profile_picture)
        updateLocalStorage(userData)
        dispatch(updateUserData(userData))
        notify(t('notification.loginSuccess'), 'success')

        // Always generate a report upon successful login
        const customersResponse = await (async () => {
          const customersResponseUnSorted = await fetchCustomersList()
          return customersResponseUnSorted?.data?.length > 0
            ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
            : []
        })()
        if (customersResponse?.length > 0) {
          const selectedCustomer = customersResponse[0]
          dispatch(updateSelectedCustomer(selectedCustomer))
          localStorage.setItem('customer', JSON.stringify(selectedCustomer))

          // Pass both is_lead_save and has_report to handleCustomerSuccess
          await handleCustomerSuccess(
            selectedCustomer,
            userData?.user?.is_lead_save,
            userData?.user?.profile?.has_report
          )
        } else {
          await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
        }
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
      }
    } catch (error) {
      console.error('Google login error:', error)
      notify(t('notification.somethingWentWrong'), 'error')
    } finally {
      setGoogleUserLoading(false)
    }
  }
  const exchangeCodeForToken = async (authCode, redirectUri) => {
    handleFetchGoogleUserInfo(authCode, redirectUri)
  }
  const handleGoogleLoginSuccess = () => {
    const redirectUri = `${window.location.origin}/auth/google/callback`
    const scopes = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/adwords',
    ]

    const authParams = new URLSearchParams({
      response_type: 'code',
      access_type: 'offline',
      client_id: googleClientId,
      redirect_uri: redirectUri,
      scope: scopes.join(' '),
      prompt: '',
    })

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${authParams}`
    const authWindow = window.open(authUrl, '_blank', 'width=500,height=600,left=100,top=100')
    const messageHandler = event => {
      if (event.origin === window.location.origin) {
        const { code } = event.data
        if (code) {
          exchangeCodeForToken(code, redirectUri) // Step 3: Get access token
          authWindow?.close() // Close popup after getting the code
          window.removeEventListener('message', messageHandler) // Remove listener after handling
        }
      }
    }
    // Adding the event listener
    window.addEventListener('message', messageHandler)
  }
  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const reportId = currentUrl.searchParams.get('reportId')
    const customerId = currentUrl.searchParams.get('customerId')

    if (reportId && customerId) {
      localStorage.setItem('copied_report_unique_id', reportId)
      localStorage.setItem('copied_report_customer_id', customerId)
    }
  }, [])

  return (
    <div>
      <img className={styles.backgroundImage} src={backgroundImage} alt="backgroundImage" />
      <div className={styles.pageWrapper}>
        <div className={styles.loginHeader}>
          <Text className={styles.title} size={40} color="black">
            {t('login.title')}
          </Text>
          <Row className={styles.row}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 12, offset: 6 }}
              xl={{ span: 8, offset: 8 }}
            >
              <Card className={styles.loginCard} hoverable>
                <Space direction="vertical" size={16}>
                  {loading ? (
                    <Spin />
                  ) : (
                    <>
                      <Text color="darkerGray">{t('login.description.part1')}</Text>
                      <Text size={12} color="primary">
                        {t('login.description.part2')}
                        <strong> {t('login.description.part3')}</strong>
                      </Text>
                      {googleUserLoading ? (
                        <div style={{ textAlign: 'center' }}>
                          <Spin />
                        </div>
                      ) : (
                        <SignInWithGoogleButton onClick={handleGoogleLoginSuccess} />
                      )}

                      <FacebookSSOForMainFlow />
                      <Divider>OR</Divider>
                      <EmailLoginPage mainFlow />
                    </>
                  )}
                </Space>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LoginPage
